<template>
  <div>
    <div class="create-item-child-head">
      <div class="create-item-child-img-modal">
        <img :src="parsedProduct.image" alt="" height="300" width="300">

      </div>
      <div class="create-item-child-details">
        <h1>{{ parsedProduct.title }}</h1>
        <p>{{ parsedProduct.description }}</p>

        <div class="create-item-icon">
          <span>⏰ 15 - 20 min</span>
        </div>
      </div>
    </div>
    <div class="create-item-child-body">
      <div class="create-item-child-body-head">
        <h1>Ingredients</h1>
        <div class="create-items-body-btn">
      <span v-for="(variant, index) in parsedProduct.variant"
            :key="variant.id"
            :class="{ 'active': selectedVariantIndex === index, 'variant-active': selectedVariantIndex === index,'item-selected': isVariantItemSelected && selectedVariantIndex === index }"
            @click="selectVariant(index)">
        {{ variant.title }}
      </span>
        </div>
      </div>

      <div class='create-item-menus row'>
        <div class="create-item-menu col-12 col-lg-5"
             v-for="item in parsedProduct.variant[selectedVariantIndex].items"
             :key="item.id"
             :class="{ 'selected': item.selected }">
          <img :src="item.image" alt="" width="100" height="100">
          <div class="create-item-priceing-area">
            <h1 class="create-item-name">{{ item.title }}</h1>
            <div class="create-item-price-quantity">
<!--              <p>{{ item.calories }} Cal</p>-->
              <h1>{{ item.price }}<sup>$</sup></h1>
            </div>

            <transition name="slide">
              <div class="item-options d-flex" v-if="item.showOptions">
                <div class="me-4" v-for="option in availableOptions(item)" :key="option.id">
                  <input type="radio" :id="'option-' + option.id" :value="option.title" v-model="item.selectedOption">
                  <label :for="'option-' + option.id">{{ option.title }}</label>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "VariantShow",
  props: ['product'],
  data() {
    return {
      parsedProduct: this.product,
      selectedVariantIndex: 0,
    };
  },
  created() {
    console.log(this.product);
  },
  computed: {
    isVariantItemSelected() {
      return this.parsedProduct.variant[this.selectedVariantIndex].items.some(item => item.selected);
    }
  },
  methods: {
    selectVariant(index) {
      this.selectedVariantIndex = index;
    },
    availableOptions(item) {
      return item.options.filter(option => option.availability);
    },
  }
}
</script>

<style scoped>
.selected {
  border: 2px solid #ff6600 !important; /* Example border color */
}

.items-seleted {
  background: limegreen !important;
  color: white !important;
}

.variant-active {
  background: #F99F00 !important;
  color: white !important;
}

.create-item-child {
  padding: 80px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-bottom: 0;

}

.create-item-child-head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 2rem;
}

.create-item-child-img-modal {
  filter: drop-shadow(8px 8px 10px gray);
}

.create-item-child-details {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.create-item-child-details h1 {
  color: #691209;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
  margin-bottom: 36px;

}

.create-item-child-details p {
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
  padding-bottom: 18px;
  margin-bottom: 28px;
  border-bottom: 2px solid #691209;
}

.create-item-icon {
  display: flex;
  gap: 22px;
  align-items: center;
  margin-bottom: 40px;
}

.create-item-icon span {
  color: #4A4A4A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */

}

.create-item-price-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-item-price-area h1 {
  color: #691209;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: 60px; /* 100% */
  margin-bottom: 0;
}

.create-item-price-area h1 sup {
  color: #691209;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;

}

.create-item-quantity {
  display: flex;
  align-items: center;
  gap: 28px;
}

.create-item-quantity img {
  width: 40px;
  height: 40px;
}

.create-item-quantity span {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 62.5% */
}

.create-item-child-body-head h1 {
  color: #691209;
  margin-bottom: 40px;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
}

.create-items-body-btn {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 80px;
}

.create-items-body-btn span {
  padding: 14px 28px;
  border: 2px solid #E7EAF3;
  background: #FFF;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  cursor: pointer;
  transition: 0.5s;
}

.create-items-body-btn span:hover {
  background: #F99F00;
  color: white;
}

.create-items-body-btn {

}

.create-item-menus {
  display: flex;
  align-items: center;
  gap: 80px;
}

.create-item-menu img {
  width: 6.25rem;
  height: 6.25rem;
}

.create-item-menu {
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1.5px solid #E7EAF3;
  background: #FFF;
  justify-content: space-between;
  transition: .3s;
}

.create-item-menu:hover {
  border-radius: 20px;
  border: 2px solid #691209;
  background: #FFF;
  box-shadow: 0px 27px 80px 0px rgba(201, 203, 208, 0.27);
}

.create-item-menu .create-item-name {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  text-transform: capitalize;
  margin-bottom: 24px;
}

.create-item-price-quantity {
  display: flex;
  align-items: center;
  gap: 32px;
}

.create-item-price-quantity p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
  text-transform: capitalize;
  margin-bottom: 0;
}

.create-item-price-quantity h1 {
  color: #1E1E1E;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 222.222% */
  margin-bottom: 0;
}

.create-item-price-quantity sup {
  color: #1E1E1E;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
}

.item-child-quantity {
  display: flex;
  align-items: center;
  gap: 20px;
}

.item-child-quantity img {
  width: 20px;
  height: 20px;
}

.item-child-quantity span {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 100% */
}

@media only screen and (max-width: 599px) {
  .create-item-child-img-modal {
    width: 300px;
    height: 301px;
  }

  .create-item-child-img-modal img {
    width: 100%;
    height: 100%;
  }

  .create-item-child {
    padding: 80px 20px;
  }

  .create-item-child-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .create-item-menus {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .create-items-body-btn {
    gap: 10px;
    overflow-x: scroll;
  }

  .create-items-body-btn span {
    padding: 15px 16px;
    font-size: 1.5rem;
    line-height: 14px;
    text-align: center;
  }

  .create-item-menu > img {
    width: 90px;
    height: 90px;
  }

  .create-item-menus {

    gap: 40px;
  }

  .create-item-quantity img {
    width: 20px;
    height: 20px;
  }

  .create-item-quantity {
    gap: 15px;
  }

  .item-child-quantity {
    gap: 15px;
  }

  .create-item-price-area h1 {
    color: #691209;
    font-size: 45px;
  }

  .create-item-price-quantity {
    gap: 10px;
  }

  .create-item-price-quantity h1 {
    font-size: 15px;
  }

  .create-item-quantity span {
    color: #000;
    font-size: 25px;
  }

  .create-item-menu .create-item-name {
    font-size: 18px;
  }

  .create-item-price-quantity p {
    font-size: 14px;
  }

  .item-child-quantity img {
    width: 15px;
    height: 15px;
  }

  .item-child-quantity span {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .create-item-child-img-modal {
    width: 300px;
    height: 301px;
  }

  .create-item-child-img-modal img {
    width: 100%;
    height: 100%;
  }

  .create-item-child {
    padding: 80px 40px;
  }

  .create-item-child-head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  /* .create-item-menus {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
  } */
  .create-items-body-btn {
    gap: 10px;
  }

  .create-items-body-btn span {
    padding: 15px 16px;
    font-size: 1.5rem;
    line-height: 14px;
    text-align: center;
  }

  .create-item-menus {

    gap: 40px;
  }

  .create-item-quantity img {
    width: 20px;
    height: 20px;
  }

  .create-item-quantity {
    gap: 20px;
  }

  .create-item-price-area h1 {
    color: #691209;
    font-size: 45px;
  }

  .create-item-quantity span {
    color: #000;
    font-size: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1299px) {
  .create-item-icon {
    gap: 17px;
  }

  .create-item-child {
    padding: 80px 40px;
  }

  .create-items-body-btn {
    gap: 16px;
  }

  .create-items-body-btn span {
    padding: 14px 18px;
    font-size: 1.5rem;
  }

  .create-item-menus {
    gap: 30px;
  }

  .create-item-menu {
    padding: 15px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .create-items-body-btn {
    gap: 30px;
  }
}

</style>