<template>
  <div class="tab-accordion-container" style="margin-bottom: 5rem;">
    <!-- Menu Link Section -->
    <div class="menu-link text-center mb-3">
      <button
        class="btn btn-secondary"
        style="width: fit-content;"
        @click="showMenuModal = true"
      >
        View Full Menu
      </button>
    </div>
    <!-- Tab Headers -->
    <ul class="nav nav-tabs justify-content-center custom-tabs" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ active: isPickup }"
          @click="selectPickup"
          role="tab"
        >
          🚗 Pickup
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          :class="{ active: isDelivery }"
          @click="selectDelivery"
          role="tab"
        >
          🛵 Delivery
        </button>
      </li>
    </ul>

    <!-- Tab Content -->
    <div class="tab-content mt-4">
      <!-- Pickup Section -->
      <div
        class="tab-pane fade"
        :class="{ 'show active': isPickup }"
        role="tabpanel"
      >
        <div class="homepage-sections text-center">
          <p class="subheading">Order and collect your favorites directly from us!</p>
        </div>
      </div>

      <!-- Delivery Section -->
      <div
        class="tab-pane fade"
        :class="{ 'show active': isDelivery }"
        role="tabpanel"
      >
        <div class="delivery-cards d-flex flex-wrap justify-content-center gap-4">
          <div
            class="card custom-card"
            v-for="platform in deliveryPlatforms"
            :key="platform.name"
          >
            <img
              :src="platform.logo"
              :alt="platform.name"
              class="card-img-top"
            />
            <div class="card-body text-center">
              <h5 class="card-title">{{ platform.name }}</h5>
              <a
                :href="platform.link"
                target="_blank"
                class="btn btn-primary custom-btn"
              >
                Order Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showMenuModal" class="modal fade show d-block" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title">Menu</h5>
            <button type="button" class="close" style="width: fit-content;" @click="showMenuModal = false">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <img src="./Menu/menu.jpg" alt="Menu" class="img-fluid" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showMenuModal = false">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPickup: true,
      isDelivery: false,
      showMenuModal: false,
      deliveryPlatforms: [
        {
          name: "Toast",
          logo: "/static/logo/toast.png",
          link: "https://www.toasttab.com/local/order/keebabi-3425-hancock-st/r-c642ce07-e092-4a13-91d9-53305cba80a9",
        },
        {
          name: "DoorDash",
          logo: "/static/logo/doordash.webp",
          link: "https://www.doordash.com/store/keebabi-san-diego-31056838/\n",
        },
        {
          name: "Grubhub",
          logo: "/static/logo/grubhub.png",
          link: "https://www.grubhub.com/restaurant/keebabi-3425-hancock-st-san-diego/8744808",
        },
      ],
    };
  },
  methods: {
    selectPickup() {
      this.isPickup = true;
      this.isDelivery = false;
      this.emitter.emit('isPickup', 'PickUp');
    },
    selectDelivery() {
      this.isPickup = false;
      this.isDelivery = true;
      this.emitter.emit('isDelivery', 'Delivery');
    },
  },
};
</script>

<style>
/* Tab Container */
.tab-accordion-container {
  margin: auto;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Tabs */
.custom-tabs .nav-link {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px 20px;
  color: #555;
  transition: all 0.3s ease-in-out;
}
.custom-tabs .nav-link.active {
  color: #fff;
  background: linear-gradient(45deg, #bf0856, #c80739);
  border-radius: 8px;
}
.custom-tabs .nav-link:hover {
  background-color: #ffe4c4;
  color: #000;
  border-radius: 8px;
}

/* Section Content */
.heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ff4500;
  margin-bottom: 10px;
}
.subheading {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}
.section-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Cards */
.custom-card {
  width: 18rem;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}
.custom-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}
.custom-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.custom-btn {
  background: #691209;
  border: none;
  color: #fff;
  font-size: 1rem;
  padding: 8px 15px;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}
.custom-btn:hover {
  background: #691209;
  transform: scale(1.05);
}
</style>
