<template>

  <div class="order-catering-child-bg">
    <img class="order-catering-child-bg-gif" src="./Catering-child-page-image/Order-catering-child-bg-gif.gif" alt="">
    <img src="./Catering-child-page-image/Catering-child-bg.png" alt="">
    <div class="order-catering-child-bg-component">
      <a href="#email-catering">
        <div class="img-two"></div>
      </a>
      <div class="img-right-side">
        <div class="img-three">
          <img src="./catering-converted/Catering-Page-Hero-1.webp" alt="" height="600" width="600">
        </div>
        <div class="img-four"></div>
        <div class="img-six"></div>
        <div class="img-seven"></div>
      </div>
    </div>
  </div>

  <div class="order-catering-child" id="order-catering-child">
    <div class="order-catering-child-header">
      <h1>Catering for all occasions</h1>
      <p>Experience the Fresh Taste At A Great Price, Exclusively Available For Those Who Love Delicious Meals And Are
        Always Hungry For More!</p>
    </div>
    <div class="order-caterings-card  ">
      <div class="order-catering-card-compo position-relative d-flex align-items-center ">
        <div class="order-catering-card-left">
          <img src="./catering_new/team-gathering.png" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>Team Gathering</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <a href="/static/catering-menu/8.jpg" class="order-read-more" target="_blank_">See Packages</a>
          </div>
        </div>

      </div>
      <div class="order-catering-card-compo position-relative d-flex align-items-center ">
        <div class="order-catering-card-left">
          <img src="./catering_new/family-gathering.png" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>Family Gathering</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <a href="/static/catering-menu/7.jpg" class="order-read-more" target="_blank_">Check Details</a>
          </div>
        </div>

      </div>
      <div class="order-catering-card-compo position-relative d-flex align-items-center ">
        <div class="order-catering-card-left">
          <img src="./catering_new/ready-to-go.jpg" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>Ready To Go Meal Boxes</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <a href="/static/catering-menu/5.jpg" class="order-read-more" target="_blank_">See Boxes</a>
          </div>
        </div>

      </div>
      <div class="order-catering-card-compo position-relative d-flex align-items-center ">
        <div class="order-catering-card-left">
          <img src="./catering_new/built-own-wraps.png" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>Built Your Own Wraps</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <a href="/static/catering-menu/4.jpg" class="order-read-more" target="_blank_">Start Building</a>
          </div>
        </div>

      </div>
      <div class="order-catering-card-compo position-relative d-flex align-items-center ">
        <div class="order-catering-card-left">
          <img src="./catering_new/pre-buildt-bowls.png" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>Pre-Built Bowls</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <a href="/static/catering-menu/6.jpg" class="order-read-more" target="_blank_">Browse Bowls</a>
          </div>
        </div>

      </div>
      <div class="order-catering-card-compo position-relative d-flex align-items-center ">
        <div class="order-catering-card-left">
          <img src="./catering_new/vegie-fest.png" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>Veggie Feast</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <a href="/static/catering-menu/1.jpg" class="order-read-more" target="_blank_">Explore Dishes</a>
          </div>
        </div>

      </div>
      <div class="order-catering-card-compo position-relative d-flex align-items-center ">
        <div class="order-catering-card-left">
          <img src="./catering_new/surf-turf.webp" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>Surf & Turf</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <a href="/static/catering-menu/2.jpg" class="order-read-more" target="_blank_">View Options</a>
          </div>
        </div>

      </div>
      <div class="order-catering-card-compo position-relative d-flex align-items-center ">
        <div class="order-catering-card-left">
          <img src="./catering_new/salad-sides.webp" alt="">
        </div>
        <div class="order-catering-card-right">
          <h1>Salad & Sides</h1>

          <div class="d-flex flex-column  justify-content-center align-items-start">
            <a href="/static/catering-menu/3.jpg" class="order-read-more" target="_blank_">See Sides</a>
          </div>
        </div>

      </div>


    </div>
  </div>

  <div class="fudo-app" style="background: none">
    <div class="fudo-app-container d-flex">
      <div class="fudo-app-content w-50">
        <!--        <p>Coming Soon</p>-->
        <h1>Get Started With <br> KEEBABI Catering Today!</h1>
        <h5>Discover food wherever and whenever and get your food delivered quickly.</h5>

        <!--        <a class="fudo-btn" href="#">-->
        <!--          Order Now-->
        <!--        </a>-->
      </div>
      <div class="fudo-animated-img w-50 position-relative">
        <!--        <img class="fudo-animated-img-two" src="./catering_new/ez.png" alt="">-->
        <!--        <img class="fudo-animated-img-four" src="./Fudo-image/fudo-enimated-four.png" alt="">-->
        <img class="fudo-animated-img-one" src="./catering_new/keebabi.png" alt="">
        <!--        <img class="fudo-animated-img-three" src="./Fudo-image/fudo-enimated-three.png" alt="">-->
        <!--        <img class="fudo-animated-img-five" src="./Fudo-image/fudo-enimated-five.png" alt="">-->
      </div>
    </div>
  </div>

  <div class="planning-div" id="email-catering">
    <div class="heading text-center primary-color">
      <h1>Planning A Special Event?</h1>
      <span>We've Got You Covered!</span>
    </div>
    <div class="details d-block d-md-flex justify-content-between gap-5">
      <div class="left d-grid">
        <span class="mb-4 mb-md-0"><strong class="primary-color"><sup>*</sup>Ordering: </strong>Please place your catering order at least 48 hours in advance via
          <a :href="'mailto:' + cateringEmail" class="btn btn-outline-primary"
             style="border-color: #691209; color: #691209; background-color: transparent;">E-mail</a></span>
        <span class="mb-4 mb-md-0"><strong class="primary-color"><sup>*</sup>How To Order: </strong>Sign in and email us your details, and we will get in touch with you to confirm your order and discuss any specific requirements.</span>
        <span>We look forward to helping make your event a success!</span>
      </div>
      <div class="right d-flex justify-content-center mt-4">
        <img src="./catering-converted/planning-image.png" height="300" width="300" alt="packet">
      </div>
    </div>
    <!--    <div class="d-flex align-items-center">-->
    <!--      <a class="fs-4 text-decoration-none fw-bold"-->
    <!--         href="https://keebabi.com/static/website/pdf/Keebabi Menu.pdf" target="_blank">-->
    <!--        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--          <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"-->
    <!--                d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z"-->
    <!--                fill="#1C274C"/>-->
    <!--          <path fill-rule="evenodd" clip-rule="evenodd"-->
    <!--                d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z"-->
    <!--                fill="#1C274C"/>-->
    <!--        </svg>-->
    <!--        Click here to see the Menu-->
    <!--      </a>-->
    <!--    </div>-->
  </div>


  <div class="confirm-order d-none">
    <div class="cofirm-order-head">
      <h1>Confirm Your Order</h1>
      <p>Just review your choices, check the details, and click 'Submit' to finalize. We'll handle the rest!</p>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="confirm-order-input">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path
              d="M12.8004 10.8C15.2304 10.8 17.2004 8.83005 17.2004 6.4C17.2004 3.96995 15.2304 2 12.8004 2C10.3703 2 8.40039 3.96995 8.40039 6.4C8.40039 8.83005 10.3703 10.8 12.8004 10.8Z"
              fill="#691209"/>
          <path
              d="M21.6 19.0501C21.6 21.7836 21.6 24.0001 12.8 24.0001C4 24.0001 4 21.7836 4 19.0501C4 16.3166 7.9402 14.1001 12.8 14.1001C17.6598 14.1001 21.6 16.3166 21.6 19.0501Z"
              fill="#691209"/>
        </svg>
        <input type="text" placeholder="Full Name" v-model="name">
      </div>
      <p v-if="errors.name" class="error-message">{{ errors.name }}</p>
      <div class="confirm-order-input">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <g clip-path="url(#clip0_1269_1356)">
            <path
                d="M20.8422 8.48895L24.7204 12.0334C25.189 12.4677 25.0552 12.8026 24.4197 12.8026H21.5108V22.0632C21.5108 22.6987 20.9759 23.2668 20.3404 23.2668H15.2918V16.7803C15.2918 16.1448 14.7237 15.5767 14.0882 15.5767H10.9118C10.2763 15.5767 9.70823 16.1448 9.70823 16.7803V23.2668H4.65961C4.02412 23.2668 3.45603 22.6987 3.45603 22.0632V12.8026H0.580271C-0.0552193 12.8026 -0.18895 12.4677 0.279644 12.0334L11.6136 1.73606C12.0822 1.3017 12.8846 1.3017 13.3853 1.73606L15.9594 4.04265V2.20358C15.9594 1.56809 16.5275 1 17.1629 1H19.6707C20.3062 1 20.8411 1.56809 20.8411 2.20358L20.8422 8.48895Z"
                fill="#691209"/>
          </g>
          <defs>
            <clipPath id="clip0_1269_1356">
              <rect width="25" height="25" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <input type="text" placeholder="Address" v-model="address">
      </div>
      <p v-if="errors.address" class="error-message">{{ errors.address }}</p>
      <div class="confirm-order-input">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path
              d="M21.8935 22.9999C21.8376 22.9999 21.7826 22.998 21.7267 22.9934C11.241 22.1813 2.76281 13.7077 2.00565 3.2834C1.98457 2.99485 2.02264 2.70503 2.11753 2.43171C2.21242 2.15839 2.36213 1.90733 2.55748 1.69391C2.74812 1.48555 2.9799 1.31899 3.23819 1.20476C3.49647 1.09053 3.77564 1.03111 4.05805 1.03025L8.36819 1C9.32518 1 10.1529 1.64258 10.3839 2.56199L11.2841 6.13788C11.384 6.53273 11.3642 6.94838 11.2273 7.33196C11.0903 7.71555 10.8424 8.04974 10.515 8.29203L8.78344 9.58178C10.3027 12.3479 12.572 14.6289 15.3302 16.1625L16.6685 14.3897C16.9136 14.0645 17.249 13.8187 17.633 13.683C18.0169 13.5474 18.4323 13.5279 18.8273 13.627L22.4279 14.5207C22.8763 14.6305 23.2749 14.8875 23.5599 15.2506C23.8449 15.6138 23.9999 16.062 24 16.5236V20.9365C24 21.5121 23.7672 22.0447 23.3437 22.437C22.9479 22.7998 22.4304 23.0007 21.8935 22.9999Z"
              fill="#691209"/>
        </svg>
        <input type="text" placeholder="Phone Number" v-model="phone">
      </div>
      <p v-if="errors.phone" class="error-message">{{ errors.phone }}</p>


      <input class="confirm-submit" type="submit" name="" id="" value="Submit">
      <div class="form-error" v-if="showError">{{ showError }}</div>
    </form>
  </div>


  <div id="carouselExampleDark" class="carousel carousel-dark slide d-none" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
              aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="2000">
        <img src="./catering-converted/catering-1.webp" class="d-block w-100" alt="...">

        <div class="carousel-caption">

          <div class="carousel-text">
            <h5>Top In The Week</h5>
            <h1>Choose Your <br>
              Healthy Lifestyle.</h1>
            <p>
              Presentation matters. Our fresh Vietnamese vegetable rolls look good and taste even better
            </p>

            <!--            <a class="carousel-text-btn" href="">ORDER NOW</a>-->
          </div>
        </div>
      </div>
      <div class="carousel-item  " data-bs-interval="2000">
        <img src="./catering-converted/catering2.webp" class="d-block w-100" alt="...">

        <div class="carousel-caption ">

          <div class="carousel-text">
            <h5>Top In The Week</h5>
            <h1>The Best <br>
              Health Fresh</h1>
            <p>
              Presentation matters. Our fresh Vietnamese vegetable rolls look good and taste even better
            </p>

            <!--            <a class="carousel-text-btn" href="">ORDER NOW</a>-->
          </div>
        </div>
      </div>
      <div class="carousel-item  " data-bs-interval="2000">
        <img src="./catering-converted/catering3.webp" class="d-block w-100" alt="...">

        <div class="carousel-caption ">

          <div class="carousel-text">
            <h5>Top In The Week</h5>
            <h1>Fresh Bread <br>
              Oatmeal Crumble.</h1>
            <p>
              Presentation matters. Our fresh Vietnamese vegetable rolls look good and taste even better
            </p>


            <!--            <a class="carousel-text-btn" href="">ORDER NOW</a>-->
          </div>
        </div>
      </div>

    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">

    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">

    </button>
  </div>

</template>

<script>

// import VariantShow from "@/templates/assets/js/components/VariantShow";

export default {
  // components: {VariantShow},
  props: ['products', 'cateringEmail'],
  data() {
    return {
      parsedProduct: JSON.parse(this.products).map(product => ({
        ...product,
        isVisible: false // Add isVisible property to each product
      })),
      name: '',
      address: '',
      phone: '',
      errors: {},
      showError: ''
    };
  },

  methods: {
    toggleReadMore(index) {
      this.parsedProduct[index].isVisible = !this.parsedProduct[index].isVisible;
    },
    validateForm() {
      this.errors = {};
      if (!this.name) {
        this.errors.name = 'Name is required.';
      }
      if (!this.address) {
        this.errors.address = 'Address is required.';
      }
      if (!this.phone) {
        this.errors.phone = 'Phone number is required.';
      } else if (!this.validPhone(this.phone)) {
        this.errors.phone = 'Invalid phone number.';
      }

      return Object.keys(this.errors).length === 0;
    },
    validPhone(phone) {
      const re = /^\d{10,15}$/;
      return re.test(phone);
    },
    async handleSubmit() {
      // Collect the form data
      const formData = new FormData();
      formData.append('full_name', this.name);
      formData.append('address', this.address);
      formData.append('phone_number', this.phone);
      try {
        const response = await fetch(window.location.href, {
          method: 'POST',
          headers: {
            'X-CSRFToken': this.getCookie('csrftoken')
          },
          body: formData
        });

        if (response.ok && this.validateForm()) {
          const data = await response.json();
          if (data.success) {
            // Redirect to another page on successful login
            this.showError = 'Your request placed! Our agent will contact you soon..';
            this.name = ""
            this.address = ""
            this.phone = ""
          } else {
            // Handle login failure (e.g., show error message)
            this.showError = 'Something went wrong.';
          }
        } else {
          this.showError = 'Something went wrong.';
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    },
  },
  watch: {
    // Ensuring no scrolling when modal is visible
    parsedProduct: {
      handler(newVal) {
        if (newVal.some(product => product.isVisible)) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      },
      deep: true
    }
  }

};
</script>

<style scoped>
.heading h1 {
  font-size: 3.25rem;
  margin-bottom: 0.5rem;
}

.heading span {
  font-size: 1.5rem;
}

.left span {
  font-size: 1.5rem;
}

.primary-color {
  color: #691209;
}

.planning-div {
  margin: 5rem 5rem;
}

.planning-div h1 {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 57px;
}

.planning-div .details {
  margin-top: 3.25rem;
}

.planning-div .details .left span {
  text-align: justify;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
}

.modal-body {
  padding: 1rem 3rem;
}

.modal-header button {
  width: fit-content;
}

.modal-dialog {
  max-width: 50rem !important;
  min-width: 22.5rem !important;
}

.carousel-indicators [data-bs-target] {
  border-radius: 0;
}

.carousel-text-btn {
  color: #FFF;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 176.923% */
  text-transform: uppercase;
  padding: 12px 50px;
  border-radius: 50px;
  background: #691209;
  border: 1px solid #691209;
  text-decoration: none;

}


.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin: auto;
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  filter: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: transparent;
}

.carousel-control-prev-icon {
  background-image: url('./Carousel-image/left.png');
}

.carousel-control-next-icon {
  background-image: url('./Carousel-image/right.png');
}

.carousel-control-prev,
.carousel-control-next {
  width: 8%;
  opacity: 1;
}

.carousel-item {
  padding: 0 10px;
  /* Add padding to create gap between items */
}

.carousel-item img {
  width: calc(100% - 20px);
  /* Adjust width to account for padding */
  margin: auto;
}

.carousel-text h5 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 164.286% */
  text-transform: uppercase;
  margin-bottom: 30px;
}

.carousel-text h1 {
  color: #FFF;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}

.carousel-text p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 143.75% */
  margin-bottom: 30px;
}

.carousel-caption {
  position: absolute;
  right: 50%;
  bottom: 19.25rem;
  top: 20%;
  left: 10%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  text-align: start;
}


.order-catering-child-bg {
  position: relative;
  height: 800px;
}

.order-catering-child-bg-gif {
  position: absolute;
  width: 62% !important;
  height: 76% !important;
}

.order-catering-child-bg > img {
  width: 100%;
  height: 100%;
}

.order-catering-child-bg-component {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
}

/*.order-catering-child-bg-component .img-one::before {
  content: '';
  width: 261.558px;
  height: 261.558px;
  transform: rotate(0deg);
  background: url(./Catering-child-page-image/Catering-child-component-six.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 11%;
  left: -3%;
  animation: chicken 5s infinite;
}*/

@keyframes chicken {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}


.order-catering-child-bg-component .img-two::before {
  content: '';
  width: 80.628px;
  height: 80px;
  background: url(./Catering-child-page-image/down-arrow.png);
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 80%;
  left: 44%;
  animation: downArrow 2s infinite;
}

@keyframes downArrow {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}

.img-right-side .img-three img {
  width: 600px;
  height: 600px;
  transform: translate(0%, 57%);
}


.order-catering-child {
  margin: 5rem;
  height: fit-content;

}

.order-catering-child-header {
  width: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 80px;
}

.order-catering-child-header h1 {
  color: #691209;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 100% */
  letter-spacing: 1.44px;
  margin-bottom: 12px;
}

.order-catering-child-header p {
  color: #333;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 200% */
  margin: 0;
}

.order-caterings-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;

}

.order-catering-card-compo {
  gap: 25px;
  border-radius: 20px;
  border: 1px solid #E7EAF3;
  background: #FFFDFD;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 25px 25px 0;
  overflow: hidden;
}

.order-catering-card-left img {
  left: -129px;
  position: relative;
  width: 250px;
  left: -129px;
  height: 250px;
}

.order-catering-card-compo .order-catering-card-left img,
.order-catering-card-compo .order-catering-card-right {
  transition: transform 0.3s ease; /* Adjust the duration and timing function as needed */
}

.order-catering-card-compo:hover .order-catering-card-left img {
  transform: translateX(48px); /* Adjust the value to control the amount of slide */
}

.order-catering-card-compo:hover .order-catering-card-right {
  transform: translateX(48px); /* Adjust the value to control the amount of slide */
}

.order-catering-card-left {
  width: 30%;
}

.order-catering-card-right {
  width: 70%;
}

.order-catering-card-right h1 {
  color: #691209;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 18px;
}

.catering-child-lifestyle-right p {
  color: #4A4A4A;
  text-align: justify;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 30px;
}

.order-catering-btn {
  border-radius: 50px;
  background: #691209;
  padding: 14px 20px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: capitalize;
  border: none;
  margin-bottom: 10px;
  text-decoration: none;
  display: inline-block;
}

.order-catering-card-right .order-read-more {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-transform: capitalize;
  padding: 14px 20px;
  text-decoration: none;
  border: 1px solid #691209;
  border-radius: 1.5rem;
}

.order-catering-card-right .order-read-more:hover {
  background: #691209;
  color: white;
  border: 1px solid transparent;
}

.catering-child-lifestyle {
  background: url(./Catering-child-page-image/Catering-child-bg-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 80px 0;
  height: 810px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 80px;
  padding: 80px;
}


.lifestyle-content h3 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 164.286% */
  text-transform: uppercase;
  margin-bottom: 30px;
}

.lifestyle-content h1 {
  color: #FFF;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}

.lifestyle-content p {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  margin-bottom: 30px;
}

.lifestyle-btn {
  padding: 12px 50px;
  border-radius: 100px;
  background: #691209;
  width: fit-content;
  color: #FFF;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px; /* 176.923% */
  text-transform: uppercase;
}


/* confirm form  */


.confirm-order {
  background: url(./Create-Item-child-page-image/Create-item-child-from-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 60px;
  width: 100%;
  height: 100vh;
  margin: 80px 0;
  background-position: center;

}

.cofirm-order-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.cofirm-order-head h1 {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  color: #691209;
  font-weight: 700;
}

.cofirm-order-head p {
  color: #1E1E1E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.42px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

}

.confirm-order-input {
  display: flex;
  align-items: center;
  gap: 36px;
  padding: 14px 36px;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #FFF;

}

.confirm-order-input input {
  width: 100%;
  color: #4A4A4A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 0.42px;
  border: none;
  outline: none;
  padding: 10px;
}

.confirm-submit {
  margin-top: 30px;
  padding: 18px 0;
  width: 100%;
  border: 1px solid rgba(19, 33, 68, 0.10);
  background: #691209;
  box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
  color: var(--Main-gohan, #FFF);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 100% */
  letter-spacing: 0.54px;
}


@media only screen and  (max-width: 599px) {
  .order-catering-child {
    margin: 5rem 2.5rem;
  }

  .planning-div {
    margin: 3rem 2.5rem
  }

  .img-six::after {
    width: 8.5rem !important;
    height: 8.5rem !important;
  }

  .img-four::before {
    right: 15% !important;
    background-size: 4rem !important;
  }

  .img-seven::before {
    width: 5rem !important;
    right: 0% !important;
    background-size: 5rem !important;
  }

  .img-seven::after {
    right: 15% !important;
    background-size: 4rem !important;
  }

  .img-four::after {
    width: 8rem !important;
    height: 10.25rem !important;
    background-size: 10rem !important;
  }

  .order-catering-child-header {
    width: 70%;
  }


  .order-caterings-card {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .order-catering-child-bg-gif {
    position: absolute;
    width: 100% !important;
    height: 76% !important;
  }

  .order-caterings-card-head {
    width: 250px;
  }

  .order-catering-child-details {
    margin-bottom: 10px;
  }

  .order-food-name-price h2 {
    font-size: 22px;
  }

  .order-caterings-card-body {
    padding: 20px 10px;
  }

  .catering-child-lifestyle {
    margin: 80px 0;
    align-items: center;
    gap: 0px;
    padding: 80px 20px;
    flex-direction: column-reverse;
  }

  .catering-child-lifestyle-right img {
    width: 380px;
  }

  .lifestyle-content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .img-right-side .img-three img {
    width: 12.5rem;
    height: 12.5rem;
    transform: translate(0%, 335%);
  }

  /*order-catering-child-bg-component .img-one::before {
    content: '';
    width: 229.558px;
    top: 11%;
    left: -3%;

  }*/
  .order-catering-child-bg-component .img-two::before {
    content: '';
    width: 53.628px;
    height: 75px;
    top: 66%;
    left: 37%;

  }

  .order-catering-card-left {
    width: 23%;
  }

  .order-catering-card-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .order-catering-card-left img {
    left: -120px;
    position: relative;
    width: 200px;
    height: 200px;
  }

  .order-catering-card-right h1 {
    color: #691209;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 18px;
  }


  .order-catering-child-header {
    width: 80%;
  }

  .order-catering-card-compo {
    gap: 18px;
  }

  .order-catering-card-right h2 {
    padding: 10px 64px;
  }

  .catering-child-lifestyle-right img {
    width: 340px;
  }

  .confirm-order-input {
    gap: 0px;
  }

  .order-catering-child-header h1 {
    font-size: 2.5rem;
  }

  .order-catering-child-header p {
    text-align: start;
  }

  .confirm-order {
    padding: 2rem 2.5rem;
    margin: 30px 0;
    height: 75vh;
  }


  .carousel-text {
    position: absolute;
    top: -39px;
    left: -50px;
    width: 100%;
    padding: 33px;
  }

  .carousel-text h5 {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .carousel-text h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .carousel-item img {
    height: 300px;
  }

  .carousel-text p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .carousel-text-btn {
    font-size: 11px;
    font-weight: 700;
  }

  .carousel-indicators {
    bottom: -10px;
  }

  .carousel {
    margin-bottom: 30px;
  }
}


@media only screen and (min-width: 600px) and (max-width: 991px) {
  .confirm-order {
    height: 80vh;
  }

  .order-catering-child-header {
    width: 70%;
  }

  .order-caterings-card {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .order-caterings-card-head {
    width: 250px;
  }

  .order-catering-child-details {
    margin-bottom: 10px;
  }

  .order-food-name-price h2 {
    font-size: 22px;
  }

  .order-caterings-card-body {
    padding: 20px 10px;
  }

  .catering-child-lifestyle {
    margin: 80px 0;
    align-items: center;
    gap: 0px;
    padding: 80px 40px;
    flex-direction: column-reverse;
  }

  .catering-child-lifestyle-right img {
    width: 470px;
  }

  .lifestyle-content h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .img-right-side .img-three img {
    width: 250px;
    height: 297px;
    transform: translate(0%, 198%);
  }

  .order-catering-child-bg-component .img-one::before {

    left: -5%;

  }

  .carousel-text {
    position: absolute;
    top: -39px;
    left: -50px;
    width: 100%;
    padding: 33px;
  }

  .carousel-text h5 {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .carousel-text h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .carousel-item img {
    height: 300px;
  }

  .carousel-text p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .carousel-text-btn {
    font-size: 11px;
    font-weight: 700;
  }

  .carousel-indicators {
    bottom: -10px;
  }

  .carousel {
    margin-bottom: 30px;
  }

}


@media only screen and (min-width: 992px) and (max-width: 1299px) {

  .order-caterings-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }

  .order-caterings-card-head {
    width: 287px;
  }

  .catering-child-lifestyle {
    gap: 60px;
    padding: 80px 40px;
  }

  .life-content {

  }

  .lifestyle-content h1 {
    font-size: 35px;
  }

  .img-right-side .img-three img {
    width: 430px;
    height: 450px;
    transform: translate(0%, 106%);
  }


  .carousel-text {
    position: absolute;
    top: -39px;
    left: -50px;
    width: 100%;
    padding: 33px;
  }

  .carousel-text-btn {
    font-size: 11px;
    font-weight: 700;
  }

  .carousel-indicators {
    bottom: -10px;
  }

  .carousel {
    margin-bottom: 30px;
  }

}


@media only screen and (min-width: 1300px) and (max-width: 1499px) {
  .order-caterings-card-head {

    width: 353px;
  }

  .img-right-side .img-three img {
    width: 475px;
    height: 529px;
    transform: translate(0%, 79%);
  }


}


.img-four::before {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-nine.png);
  bottom: 40%;
  right: 33%;
  position: absolute;
  width: 73px;
  height: 67px;
  animation: tree 6s ease-in infinite;
}


@keyframes tree {
  0%, 100% {
    transform: translate(0);
  }
  50% {
    transform: translate(1rem, 1rem);
  }
}


.img-four::after {
  content: '';
  background: url(./catering-converted/Catering-Page-Hero-2.webp);
  bottom: 47%;
  right: 19%;
  position: absolute;
  width: 11rem;
  height: 13.25rem;
  background-repeat: no-repeat;
  animation: potato 6.5s ease-out infinite;
}

@keyframes potato {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}


.img-five::before {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-five.png);
  bottom: 57%;
  right: 12%;
  position: absolute;
  width: 73px;
  height: 67px;
  background-repeat: no-repeat;
  animation: potatoTwo 4.5s ease-in infinite;
}

@keyframes potatoTwo {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}

.img-five::after {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-four.png);
  bottom: 68%;
  right: 21%;
  position: absolute;
  width: 73px;
  height: 67px;
  background-repeat: no-repeat;
  animation: potatoTwo 4.8s ease-in infinite;
}

@keyframes potatoTwo {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}


.img-six::after {
  content: '';
  background: url(./catering-converted/Catering-Page-Hero-3.webp);
  bottom: 65%;
  right: 0%;
  position: absolute;
  width: 12.5rem;
  height: 11.5rem;
  background-repeat: no-repeat;
  animation: chicken 5.8s ease-in infinite;
}


@keyframes chicken {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
}


.img-seven::after {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-two.png);
  bottom: 74%;
  right: 22%;
  position: absolute;
  width: 89px;
  height: 160px;
  background-repeat: no-repeat;
  animation: onion 7s ease-in infinite;
}

@keyframes onion {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(1rem);
  }
}


.img-seven::before {
  content: '';
  background: url(./Catering-child-page-image/Catering-child-component-one.png);
  bottom: 82%;
  right: 0%;
  position: absolute;
  width: 89px;
  height: 160px;
  background-repeat: no-repeat;
  animation: treeTwo 7s ease-in infinite;
}

@keyframes treeTwo {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }

}

</style>